import React from "react"

import useMediaQuery from "@material-ui/core/useMediaQuery"
import { useTheme } from "@material-ui/core/styles"
import Typography from "@material-ui/core/Typography"
import { makeStyles } from "@material-ui/core/styles"
import Container from "@material-ui/core/Container"
import List from "@material-ui/core/List"
import ListItem from "@material-ui/core/ListItem"
import ListItemText from "@material-ui/core/ListItemText"
import ListItemIcon from "@material-ui/core/ListItemIcon"
import CheckIcon from "@material-ui/icons/Check"

import Layout from "../components/layout"
import Head from "../components/head"
import teamSpritSvg from "../assets/team_spirit.svg"

const useStyles = makeStyles(theme => ({
  heroContent: {
    padding: theme.spacing(15, 0, 12),

    [theme.breakpoints.down("sm")]: {
      padding: theme.spacing(9, 0, 4),
    },
  },
  heroImage: {
    width: "100%",
  },
  heroGridContainer: {
    display: "grid",
    gridGap: theme.spacing(6),
    gridTemplateRows: "1fr",
    gridTemplateColumns: "1fr 1fr",
    justifyContent: "center",
    alignItems: "center",

    [theme.breakpoints.down("sm")]: {
      gridTemplateColumns: "1fr",
    },
  },
  headshot: {
    width: "100%",
  },
  heroGridItemImage: {
    textAlign: "center",
  },
  heroGridItemContent: {},
  whiteBg: {
    backgroundColor: theme.palette.background.paper,
  },
  heroButtons: {
    marginTop: theme.spacing(5),
  },
  cardGrid: {
    paddingTop: theme.spacing(8),
    paddingBottom: theme.spacing(8),
  },

  girdContainer: {
    display: "grid",
    gridGap: theme.spacing(4),
    gridTemplateColumns: "repeat(auto-fill, minmax(320px, 1fr))",
    marginTop: theme.spacing(3),
  },
  cardGridContainer: {
    display: "grid",
    gridGap: theme.spacing(2),
    gridTemplateColumns: "100px 1fr",
    justifyContent: "center",
    alignItems: "center",

    [theme.breakpoints.down("sm")]: {
      gridTemplateColumns: "80px 1fr",
    },
  },
  listRoot: {
    width: "100%",
  },
  heading: {
    marginTop: theme.spacing(3),
  },
}))

const AboutPage = () => {
  const classes = useStyles()
  const theme = useTheme()
  const matches = useMediaQuery(theme.breakpoints.up("md"))

  return (
    <Layout>
      <Head title="About" />
      <main>
        <div className={classes.heroContent}>
          <Container maxWidth="lg">
            <div className={classes.heroGridContainer}>
              <div className={classes.heroGridItemContent}>
                <Typography
                  component="h1"
                  variant="h2"
                  align={matches ? "left" : "center"}
                  color="textPrimary"
                  gutterBottom
                >
                  Office Goals
                </Typography>
                <Typography
                  variant="h5"
                  align={matches ? "left" : "center"}
                  color="textSecondary"
                  paragraph
                >
                  The office goals are:
                </Typography>

                <List className={classes.listRoot}>
                  <ListItem dense alignItems="center">
                    <ListItemIcon>
                      <CheckIcon />
                    </ListItemIcon>
                    <ListItemText primary="To be highly professional in having up to date knowledge and skills in handling all legal matters that we undertake." />
                  </ListItem>
                  <ListItem dense alignItems="center">
                    <ListItemIcon>
                      <CheckIcon />
                    </ListItemIcon>
                    <ListItemText primary="To provide specialist legal services in immigration law." />
                  </ListItem>
                  <ListItem dense alignItems="center">
                    <ListItemIcon>
                      <CheckIcon />
                    </ListItemIcon>
                    <ListItemText primary="To be customer focused and work with clients in all matters." />
                  </ListItem>

                  <ListItem dense alignItems="center">
                    <ListItemIcon>
                      <CheckIcon />
                    </ListItemIcon>
                    <ListItemText primary="To be friendly, tactful and honest in our dealings with people." />
                  </ListItem>

                  <ListItem dense alignItems="center">
                    <ListItemIcon>
                      <CheckIcon />
                    </ListItemIcon>
                    <ListItemText primary="To be efficient – to get all legal work done properly and promptly" />
                  </ListItem>

                  <ListItem dense alignItems="center">
                    <ListItemIcon>
                      <CheckIcon />
                    </ListItemIcon>
                    <ListItemText primary="To provide legal services at a fair cost – to offer flat fee services and be competitive in pricing for all matters." />
                  </ListItem>
                  <ListItem dense alignItems="center">
                    <ListItemIcon>
                      <CheckIcon />
                    </ListItemIcon>
                    <ListItemText primary="To provide work and training opportunities for law students and recent law graduates and to support practical legal training." />
                  </ListItem>
                  <ListItem dense alignItems="center">
                    <ListItemIcon>
                      <CheckIcon />
                    </ListItemIcon>
                    <ListItemText primary="To make the office environment comfortable and enjoyable to work in." />
                  </ListItem>
                </List>
              </div>
              <div className={classes.heroGridItemImage}>
                <img
                  src={teamSpritSvg}
                  alt="Mylan cut through communications"
                  className={classes.heroImage}
                />
              </div>
            </div>
          </Container>
        </div>
      </main>
    </Layout>
  )
}

export default AboutPage
